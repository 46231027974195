<template>
  <div class="main-content">
    <h1>{{ $t('Welcome') }}</h1>
    <p></p>
    <b-card class="card-icon-big  mb-30 welcome-good">
    <h3>{{ welcomeText }}</h3>
    </b-card>
    <b-row style="margin-top: 1rem;">
      <b-col lg="4" xl="6" md="12">
        <b-row>

          <b-col lg="6" md="6" sm="6">
            <b-card class="card-icon-big text-center mb-30 card1 cardhover"  @click="$router.push('/app/roommanagement')">
              <i class="i-Hospital"></i>
              <p class="text-muted mt-2 mb-2">{{$t('Total Care Units')}}</p>
              <p v-if="$store.state.fleet.roomsetList && $store.state.fleet.roomsetList.length" class="line-height-1 text-title text-18 mt-2 mb-0" id="WP_totalUnits">{{$store.state.fleet.roomsetList ? $store.state.fleet.roomsetList.filter(p => p.organizationLabel === $store.state.fleet.currentOrganization  && p.label.charAt(0) !== '_').length : '?' }}</p>
              <div v-else class="spinner spinner-dark mr-3" ></div>
              <!--<font-awesome-icon icon="wrench" variant="warning" class="bottom-right hcard1" ></font-awesome-icon>-->
            </b-card>
          </b-col>


          <b-col lg="6" md="6" sm="6">
            <b-card class="card-icon text-center mb-30 card2 cardhover" @click="$router.push('/app/roommanagement')">
              <i class="i-Data-Signal"></i>
              <p class="text-muted mt-2 mb-2">{{$t('Total ISA Sensors')}}</p>                                          
              <p v-if="$store.state.fleet.roomsetList && $store.state.fleet.roomsetList.length" class="text-primary text-24 line-height-1 m-0" id="WP_totalSensors">{{ $store.state.fleet.roomsetList ? $store.state.fleet.roomsetList.filter(p => p.organizationLabel === $store.state.fleet.currentOrganization  && p.label.charAt(0) !== '_').reduce(((sum, a) => sum + a.roomList.length), 0) : '?' }}</p>
              <div v-else class="spinner spinner-dark mr-3" ></div>
              <!--<font-awesome-icon  icon="wrench" variant="warning" class="bottom-right hcard2"  ></font-awesome-icon>-->
            </b-card>
          </b-col>
          
          
          <b-col lg="6" md="6" sm="6">
            <b-card class="card-icon-big text-center mb-30 card3 cardhover" @click="$router.push('/app/usersmanagement')">
              <i class="i-Add-User"></i>
              <p class="text-muted mt-2 mb-2">{{$t('Total Users')}}</p>
              <p v-if="$store.state.fleet.welcome.userCount >= 0" class="line-height-1 text-title text-18 mt-2 mb-0" id="WP_totalUsers">{{ $store.state.fleet.welcome.userCount }}</p>
              <div v-else class="spinner spinner-dark mr-3" ></div>
              <!--<font-awesome-icon  icon="wrench" variant="warning" class="bottom-right hcard3" ></font-awesome-icon>-->
            </b-card>
          </b-col>
          <b-col lg="6" md="6" sm="6">
            <b-card class="card-icon-big text-center mb-30 card4 cardhover" @click="$router.push('/app/notificationsmanagement')">
              <i class="i-Telephone"></i>
              <p class="text-muted mt-2 mb-2">{{$t('Total Phones')}}</p>
              <p v-if="$store.state.fleet.welcome.phoneCount >= 0" class="line-height-1 text-title text-18 mt-2 mb-0" id="WP_totalPhones">{{ $store.state.fleet.welcome.phoneCount  }}</p>
              <div v-else class="spinner spinner-dark mr-3" ></div>
              <!--<font-awesome-icon  icon="wrench" variant="warning" class="bottom-right hcard4" ></font-awesome-icon>-->
            </b-card>
          </b-col>
        </b-row>
      </b-col>

      <b-col lg="6" xl="6" md="12" class="mb-30">
        <b-card :title="$t('Last 6 Month Events')" class="mb-30 cardhover" @click="$router.push('/app/reporting')">
                <div id="basicArea-chart" style="min-height: 200px" v-if="$store.state.fleet.alertListPerMonthSummary && perMonthColumnCharOptions">
                    <apexchart ref="welcomeChart"  type=bar height=260 :options="perMonthColumnCharOptions" :series="$i18n.locale && $store.state.fleet.alertListPerMonthSummary ? $store.state.fleet.alertListPerMonthSummary : [{ name: $t('loading...'), data: [0]}]" />
                </div>
            </b-card>
      </b-col>
      

    </b-row>
    <b-card :class="'card-icon-big  mb-30 ' +  (!hasNIssue && cIssues.length === 0) ? 'welcome-good' : 'welcome-bad'">
        <h4 v-if="cIssues.length > 0">{{$t('Some sensors need your attention:')}}</h4>
        <ul>
            <template v-for="room in cIssues">                 
              <li v-if="!room.label.startsWith('_')" :key="room.oid"> <h4> {{$t('Sensor in {room} needs a ', { room: room.label})  }} <a style="color: #9c816d; text-decoration: underline; cursor: help;" @click="$store.commit('user/NEEDS_HELP','ISASensorOffline')">{{$t('connexion check.')}}</a> </h4></li>
            </template>						
        </ul>
        <h4 v-if="hasNIssue">{{$t('Your phone configuration')}} <a style="color: #9c816d; text-decoration: underline; cursor: help;" @click="$router.push('/app/notificationsmanagement')">{{$t('needs your attention.')}}</a> </h4>
        <h4 v-if="cIssues.length === 0 && !hasNIssue">{{$t('Every sensors are working perfectly!')}}</h4>
    </b-card>
  </div>
</template>

<script>
import moment from 'moment';






export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "MyISA"
  },

   data() {
    return {              
      welcomeText: this.$t('Hi!'),
      
    } 
  },
  methods: {
    getWelcome() {

    },
    timer() {
      if(!this.$store.state.fleet.alertList) return;
      let timings = this.$store.state.fleet.alertList.map(p => p && p.type === 5 && p.detection === "TD" ? new Date(p.date).getTime() : -1).filter(p => p > -1)
      let d = -1;
      for(const t of timings) if(t > d) d = t;
      
      
      const diff = new Date() - new Date(d);

      const tM = (30 * 24 * 60 * 60 * 1000);      
      const tD = ( 1 * 24 * 60 * 60 * 1000);
      const tH = (1 * 1 * 60 * 60 * 1000);
      const tm = (1 * 1 * 1 * 60 * 1000);
      const ts = (1 * 1 * 1 * 1 * 1000);      

      const diffM = Math.floor((diff) / tM);
      const diffD = Math.floor((diff-diffM*tM) / tD);      
      const diffH = Math.floor((diff-diffM*tM - diffD*tD) / tH);
      const diffm = Math.floor((diff-diffM*tM - diffD*tD - diffH*tH) / tm);
      const diffS = Math.floor((diff-diffM*tM - diffD*tD - diffH*tH - diffm*tm) / ts);
      
      
      let sinceLastFall =   (diffM > 0? `${diffM} ${this.$t('months')} ` : '')
                          +  (diffD > 0? `${diffD} ${this.$t('days')} ` : '')
                          +  (diffH > 0? `${diffH} ${this.$t('hours')} ` : '')
                          +  (diffm > 0? `${diffm} ${this.$t('minutes')} ` : '')
                          +  (diffS >= 0? `${diffS} ${this.$t('seconds')}` : '');
      
      if(diffM > 2) sinceLastFall = this.$t("more than 3 months") + ".";

      if(diff > tD)
        this.welcomeText = this.welcomeText1 + sinceLastFall;
      else
        this.welcomeText = this.welcomeText2.replace('{time}', sinceLastFall);
      
    }
  },
  computed: {
      cIssues() {
        const iss = [];
        for(const rs of this.$store.state.fleet.roomsetList.filter(r => r.organizationLabel === this.$store.state.fleet.currentOrganization  && r.label.charAt(0) !== '_'))
              for(const room of rs.roomList) {
                  if(room.sensor.isOnline === -1) {
                    iss.push(room)
                  }
              }
        return iss;
      },
      hasNIssue() {        
        if(this.$store.state.fleet.phoneAudit && this.$store.state.fleet.phoneAudit.fallErrors > 0)
          return true;
        return false;
      },
      
      evtext() { return  this.$t('Events')},
      welcomeText1() { return  this.$t("Congratulation! No falls occured since ")},      
      welcomeText2() { return this.$t("Congratulation! You assisted a felt patient just {time} ago")},
      perMonthColumnCharOptions() {    
        return {
          chart: {
            shadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 1
            },
              show: false,
              tools:{
                download:false // <== line to add
              }
            },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'	
            },

          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: [moment().add(-6,'month').format('MM/YY'), moment().add(-5,'month').format('MM/YY'), moment().add(-4,'month').format('MM/YY'), moment().add(-3,'month').format('MM/YY'), moment().add(-2,'month').format('MM/YY'), moment().add(-1,'month').format('MM/YY'), moment().format('MM/YY')],
          },
          yaxis: {
            title: {
              text: this.evtext
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {            
                return val
              }
            }
          },
          colors: ['#ED3434', '#4D3397', '#7d6cbb',],
          }
      }

  },
  mounted() {
    this.timer();
    setInterval(this.timer.bind(this),1000)
    this.$store.dispatch('fleet/fillReportReload');
    this.$store.dispatch('fleet/loadUsers');    
    //this is the default page on resume, so check if we need to navigate here    
    
    console.log(this.$router.currentRoute);
    if(this.$router.currentRoute && (this.$router.currentRoute.path === '/index.html' || this.$router.currentRoute.path === '/android_asset/www/index.html'))
      if(this.$store.state.user.kioskUser.oid) // is kiosk device
              this.$router.push('/app/kiosk');
          else
              this.$router.push('/');
    let self = this;
    function onResume() {
          // Handle the resume event
          console.log('resuming')
          
          if(self.$store.state.user.kioskUser.oid) // is kiosk device
              self.$router.push('/app/kiosk');
          else
              self.$router.push('/');

      }
      document.addEventListener("resume", onResume, false);
  }, 
    watch: {
            "$store.state.fleet.currentOrganization": function (value) {                  
                this.$store.dispatch('fleet/fillReportReload');
                this.$store.dispatch('fleet/loadUsers');    
    },
    '$i18n.locale': function(newVal, oldVal) {
      console.log('locale change', newVal)
      this.$store.commit('fleet/RELOAD_GRAPH', {});      
    }
  }
};
</script>



<style scoped>

.echarts {
  width: 100%;
  height: 100%;
}

.bottom-right {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.5rem;
    
}

.hcard1 {
  display: none;
  cursor: hand;
}
.hcard2 {
  display: none;
  cursor: hand;
}
.hcard3 {
  display: none;
  cursor: hand;
}
.hcard4 {
  display: none;
  cursor: hand;
}

.card1:hover  .hcard1 {
  display: block;
}
.card2:hover  .hcard2 {
  display: block; 
}
.card3:hover  .hcard3 {
  display: block; 
}
.card4:hover  .hcard4 {
  display: block; 
}

.cardhover:hover{
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
  cursor: hand;
}

.welcome-good {
  background-color: rgba(50, 141, 50, 0.05);
}

.welcome-bad {
  background-color: rgba(255, 166, 0, 0.08);
}
</style>

